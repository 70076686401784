.animation-container div {
  background: #E1C973;
  width: 100px;
  height: 15px;
  filter: blur(2px);
}

.telescope-container {
  width: 100%;
  height: 100%;
  place-content: center;
  overflow: hidden;
  border-radius: 30px;
}

.telescope-item {
  fill: none;
  width: 100%;
  overflow: visible;
  stroke: red; //old color - E1C973
  stroke-width: 10;
  stroke-linejoin: round;
  stroke-linecap: round;
  filter: blur(1px);
}
