.stellar-circle {
  -webkit-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}
.stellar-circle:hover {
  -webkit-filter: sepia(100);
  filter: sepia(100);
  cursor: pointer;
}

.arrow:hover {
  cursor: pointer;
}

.stellar-animation-container div {
  background: #3BD186;
  width: 100px;
  height: 100px;
}


.stellar-container {
  width: 100%;
  height: 100%;
  place-content: center;
  overflow: hidden;
  border-radius: 30px;
}

.item {
  fill: none;
  width: 100%;
  overflow: visible;
  stroke: #3BD186;
  stroke-width: 20;
  stroke-linejoin: round;
  stroke-linecap: round;
}
