.about-banner {
  position: relative;
  padding: 0;
  margin: 0;
}
.about-banner img {
  display: block;
  max-width: 100%;
  height: auto;
}
.about-banner:after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.about-text {
  display: block;
  position: absolute;
  width: 100%;
  color: #fff;
  left: 0;
  top: 10%;
  padding: 1em;
  font-weight: 700;
  z-index: 2;
}

.person:hover {
  cursor: pointer
}